<template>
    <div style="position: relative;">
        <div class="kiwi-fs-16 fib" style="line-height: 1.8">
            <span v-for="(part, index) in parts" :key="index">
                <template v-if="part.type === 'text'">
                    <span v-html="part.value" v-if="!dictionaryCondition"></span>
                    <template v-if="dictionaryCondition">
                        <span class="iqt-fib-words kiwi-question-word cursor-pointer" v-for="(txt, ti) in part.words" :key="`${index}-${ti}`" @click="handleClick(txt, ti, index)" :class="{'kiwi-text-primary': (selectedWords === txt && selectedIndex === `${index}-${ti}`)}"> {{txt}} </span>
                    </template>
                </template>
                <template v-else>
                    <v-text-field
                        v-if="isDrag"
                        class="kiwi-border-light-grey kiwi-bg-white kiwi-text-black mx-2 kiwi-form-input kiwi-fill-in-blank-input"
                        variant="outlined"
                        v-model="selected[part.selectIndex]"
                        v-on:dragover.prevent
                        v-on:drop="$e => handleDrop($e, part.selectIndex)"
                        hide-details
                        height="30"
                        clearable
                        readonly
                    ></v-text-field>
                    <v-text-field
                        v-else-if="isTyping"
                        class="kiwi-border-light-grey kiwi-bg-white kiwi-text-black mx-2 kiwi-form-input kiwi-fill-in-blank-input"
                        variant="outlined"
                        v-model="selected[part.selectIndex]"
                        hide-details
                        height="30"
                        clearable
                    ></v-text-field>
                    <v-select
                        v-else
                        class="kiwi-border-light-grey kiwi-bg-white kiwi-text-black mx-2 kiwi-form-input kiwi-fill-in-blank-input"
                        :items="part.options"
                        variant="outlined"
                        v-model="selected[part.selectIndex]"
                        hide-details
                        height="30"
                    ></v-select>
                </template>
            </span>
        </div>
        <v-sheet class="kiwi-bg-white kiwi-text-black pa-3 pa-md-6 mt-4 kiwi-radius-8" variant="flat" v-if="props.isDrag && dragList.length">
            <v-chip
                label
                class="ma-2 cursor-pointer kiwi-radius-4"
                v-for="(item, i) in dragList"
                :key="i"
                draggable="true"
                v-on:dragstart="handleDragStart($event, item)"
                style="font-size: 16px;"
            >{{ item.label }}</v-chip>
        </v-sheet>

        <DictionaryDialog v-if="dialog && !['fib-rw', 'fib-r', 'fib-l'].includes(slug)" @close-dialog="dialog=false" :word="word" :returnData="dictionaryStore?.dictonaryData" />
    </div>
</template>

<script setup>
import {ref, computed, reactive, watch} from 'vue'
import { removeTags } from '@/utils/utils';
import { storeToRefs } from "pinia";
import { useIndividualQustionsDictonaryStore } from "@/stores/individual-question-dictionary";
import DictionaryDialog from "@/components/IndividualQuestion/DIctionaryDialog.vue";
const props = defineProps({
    showTag: {
        type: Boolean,
        default: false,
    },
    text: {
        required: false,
    },
    options: {
        type: Array,
        required: false,
    },
    isDrag: {
        type: Boolean,
        required: false,
        default: () => false
    },
    isTyping: {
        type: Boolean,
        required: false,
        default: () => false
    },
    dictionaryCondition: {
        type: Boolean,
        required: false,
        default: false,
    }
})

const loading = ref(false);
const dialog = ref(false);
const finalWord = ref('');
const selectedWords = ref('');
const selectedIndex = ref('');
const dictionaryStore = useIndividualQustionsDictonaryStore();
const { getDictonaryData, dictonaryData } = storeToRefs(dictionaryStore)
const emit = defineEmits(["selected"]);
const parts = computed(() => {
      const regex = /\{([^}]*)\}/g;
      const textParts = removeTags(props.text).split(regex);
      const parts = [];
      let selectIndex = 0;

      for (let i = 0; i < textParts.length; i++) {
        if (i % 2 === 0) {
          if (textParts[i]) {
                parts.push({ type: 'text', value: textParts[i], words: (textParts[i] || []).split(/(?:\s|&nbsp;)+/) });
          }
        } else {
          const options = props.options.length && props.isDrag ? props.options : (textParts[i] || "").split('|').map(s => (s || '').trim());
          parts.push({ type: 'select', options, selectIndex });
          selected[selectIndex] = selected[selectIndex] || ''; // Initialize with the first option
          selectIndex++;
        }
      }
      return parts;
});
const selected = reactive({})
const dragList = computed (() => {
    const selectedVals = Object.values(selected)
    return props.options.filter(o => !selectedVals.includes(o)).map(o => ({value: o, label: o}))
})


const handleDragStart = (event, item) => {
    event.dataTransfer.setData('application/json', JSON.stringify(item))
}

const handleDrop = (event, index) => {
    const data = JSON.parse(event.dataTransfer.getData('application/json'))
    selected[index] = data.value;
}


const handleClick = (word, i, index) => {
    if(props.dictionaryCondition) {
        let text = word;
        loading.value = true;
        
        if(word.split('.')) {
            text = word.split(/[^a-zA-Z]+/);
            text = text.filter(word => word);
        } else {
            text = word;
        }

        selectedWords.value = word;
        selectedIndex.value = `${index}-${i}`;

        dictionaryStore.getDictonaryData(text).then(res => {
            dialog.value = true;
            finalWord.value = text;
            loading.value = false;
        });
    }
}

watch(selected, (newVal, oldVal) => {
  if (newVal) {
    emit("selected", Array.isArray(selected) ? selected : Object.values(selected));
  }
});
watch(dialog, (newVal, oldVal) => {
  if (newVal === false) {
    selectedWords.value = ''
  }
});
</script>

<style lang="scss" scoped>
.fib {
    line-height: 1.5;
}
.kiwi-fill-in-blank-input {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 190px;
}
.kiwi-question-word {
    &:hover, &:active, &:visited, &:focus-visible {
        color: var(--color-primary);
    }
}

.iqt-fib-words {
    display: inline-block;
    & + .iqt-fib-words {
        margin-left: 5px;
    }
}
</style>
