<template>
    <div>
        <v-row class="w-100 draggable-component">
            <v-col cols="12" md="6">
                <v-sheet 
                    class="kiwi-bg-white kiwi-text-black pa-4 kiwi-radius=5 h-100" 
                    variant="flat"
                >
                    <strong class="d-block">Source:</strong>
                    <draggable
                        class="list-group mt-4"
                        :list="list1"
                        group="people"
                        itemKey="name"
                    >
                        <template #item="{ element, index }">
                            <div class="list-group-item d-flex">
                                {{element.num}}. 
                                <template v-if="dictionaryCondition === true">
                                    <div style="flex: 1; margin-left: 10px; position: relative; z-index: 3;">
                                        <span v-for="(word, index) in element.option" :key="index" @click="handleClick(word, index)" class="cursor-pointer kiwi-question-word" :class="{'kiwi-text-primary': (selectedWords === word && selectedIndex === index)}">
                                            {{ word }}
                                            <span v-if="index !== element.option - 1"> </span> <!-- To maintain spaces between words -->
                                        </span>
                                    </div>
                                </template>
                                <template v-else>
                                    <span style="flex: 1; margin-left: 10px;" v-html="element.option"></span>
                                </template>
                            </div>
                        </template>
                    </draggable>
                </v-sheet>
            </v-col>
            <v-col cols="12" md="6">
                <v-sheet 
                    class="kiwi-bg-white kiwi-text-black pa-4 kiwi-radius=5 h-100" 
                    variant="flat"
                >
                    <strong class="d-block">Target:</strong>
                    
                    <draggable
                        class="list-group mt-4"
                        :list="list2"
                        group="people"
                        itemKey="name"
                    >
                        <template #item="{ element, index }">
                        <div class="list-group-item d-flex">
                            {{element.num}}. 
                            <template v-if="dictionaryCondition === true">
                                <div style="flex: 1; margin-left: 10px; position: relative; z-index: 3;">
                                    <span v-for="(word, index) in element.option" :key="index" @click="handleClick(word, index)" class="cursor-pointer kiwi-question-word" :class="{'kiwi-text-primary': (selectedWords.includes(index) && selectedIndex === index)}">
                                        {{ word }}
                                        <span v-if="index !== element.option - 1"> </span> <!-- To maintain spaces between words -->
                                    </span>
                                </div>
                            </template>
                            <template v-else>
                                <span style="flex: 1; margin-left: 10px;" v-html="element.option"></span>
                            </template>
                        </div>
                        </template>
                    </draggable>
                </v-sheet>
            </v-col>
        </v-row>
        <DictionaryDialog v-if="dialog && !['swt' , 'we' , 'sst'].includes(slug)" @close-dialog="dialog=false" :word="word" :returnData="dictionaryStore?.dictonaryData" />
    </div>
</template>
<script setup>
import {ref, defineProps, watch} from 'vue'
import draggable from 'vuedraggable'
import {storeToRefs} from 'pinia'
import { useIndividualQustionsDictonaryStore } from "@/stores/individual-question-dictionary";
import DictionaryDialog from "@/components/IndividualQuestion/DIctionaryDialog.vue";

const emit = defineEmits(["selected"]);
const props = defineProps({
    options: {
        type: Array,
        required: true,
    },
    dictionaryCondition: {
        type: Boolean,
        required: false,
        default: false,
    }
})

const dialog = ref(false);
const selectedWords = ref('');
const selectedIndex = ref('');
const finalWord = ref('');
const dictionaryStore = useIndividualQustionsDictonaryStore();
const { getDictonaryData, dictonaryData } = storeToRefs(dictionaryStore)

const list1 = ref(props.options.map((item, i) => {
    item.num = i + 1
    if(props.dictionaryCondition) {
        let words = item.option.replace(/<\/?[^>]+(>|$)/g, '');
        words = words.split(/ |\&nbsp;|;/);
        item.option = words;
    }
    return item
}))

const list2 = ref([
])
emit("selected", list2.value.map(v => v.id));

const handleClick = (word, i) => {
    if(props.dictionaryCondition) {
        let text = word;
        
        if(word.split('.')) {
            text = word.split(/[^a-zA-Z]+/);
            text = text.filter(word => word);
        } else {
            text = word;
        }

        selectedWords.value = word
        selectedIndex.value = i

        dictionaryStore.getDictonaryData(text).then(res => {
            dialog.value = true;
            finalWord.value = text;
        });
    }
}
watch(list2.value, (newVal, oldVal) => {
    emit("selected", newVal.map(v => v.id));
});


watch(dialog, (newVal, oldVal) => {
  if (newVal === false) {
    selectedWords.value = ''
  }
});
</script>