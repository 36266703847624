<template>
    <div class="kiwi-fs-15 hiw" style="">
        <div ref="paragraph" @click="handleWordClick" style="word-break: break-word;">
            <template v-if="dictionaryContent">
                <span v-for="(word, index) in words" :key="index"  class="word iqt-word"
                :class="{
                    'correct-word': corrects.includes(index),
                    'incorrect-word': incorrectWords.includes(index),
                    'not-hightlight': notSelectedWords.includes(index),
                    'kiwi-text-primary': (selectedWordss === word && selectedIndex === index)
                }"
                @click="handleClick(word, index)"
                >{{ word }}</span>
            </template>
            <template v-else>
                <span v-for="(word, index) in words" :key="index"  class="word"
                :class="{
                    'correct-word': corrects.includes(index),
                    'incorrect-word': incorrectWords.includes(index),
                    'not-hightlight': notSelectedWords.includes(index),
                }"
                >{{ word }}</span>
            </template>
        </div>
        <v-divider class="mt-4 mb-4" />
        <div class="d-flex flex-wrap align-center">
            <span class="font-weight-bold mr-1">Indication:</span>
            <span class="word px-2 py-1 correct-word">Correct Word</span>
            <span class="word px-2 py-1 incorrect-word">Incorrect Word</span>
            <span class="word px-2 py-1 not-hightlight">Not Highlighted</span>
        </div>
        <DictionaryDialog v-if="dialog && !['swt' , 'we' , 'sst', 'mcm-l', 'mcq', 'mcm-r', 'mcs', 'smw'].includes(slug)" @close-dialog="cloeDialogModal" :word="word" :returnData="dictionaryStore?.dictonaryData" />
    </div>
</template>
<script setup>
import AHeadline from "@/components/Evaluation/MockTests/Answers/AHeadline.vue";
import {computed, ref } from "vue";
import { useIndividualQustionsDictonaryStore } from "@/stores/individual-question-dictionary";
import DictionaryDialog from "@/components/IndividualQuestion/DIctionaryDialog.vue";
import { storeToRefs } from "pinia";
import { removeTags } from '@/utils/utils';
const props = defineProps({
    text: {
        required: true,
    },
    obtained: {
        required: true,
        type: Array
    },
    dictionaryContent: {
        type: Boolean,
        default: false
    }
})

const dialog = ref(false);
const word = ref('');
const selectedWordss = ref('');
const selectedIndex = ref('');
const finalWord = ref('');
const dictionaryStore = useIndividualQustionsDictonaryStore();
const { getDictonaryData, dictonaryData } = storeToRefs(dictionaryStore)

const words = computed (()=> {
    const paragraph = removeTags(props.text);
    return paragraph.split(' ')
})
const selectedWords = computed(() => {
    return props.obtained.filter(o => o.selected).map(o => parseInt(o.index))
})
const corrects = computed(() => {
    return props.obtained.filter(o => o.is_correct).map(o => parseInt(o.index))
})
const incorrectWords = computed(() => {
   return props.obtained.filter(o => !o.is_correct && o.is_selected).map(o => parseInt(o.index))
})
const notSelectedWords = computed(() => {
    return props.obtained.filter(o => !o.is_selected).map(o => parseInt(o.index))
})


const handleClick = (word, i) => {
    if(props.dictionaryContent) {
        let text = word;
        
        if(word.split('.')) {
            text = word.split(/[^a-zA-Z]+/);
            text = text.filter(word => word);
        } else {
            text = word;
        }

        selectedWordss.value = word
        selectedIndex.value = i

        dictionaryStore.getDictonaryData(text).then(res => {
            dialog.value = true;
            finalWord.value = text;
        });
    }
}

const cloeDialogModal = () => {
  dialog.value = false
  word.value = ''
  selectedWordss.value = ''
}
</script>
<style lang="scss" scoped>
.word {
  cursor: pointer;
  margin-right: 3px;
  display: inline-block;

  &:hover {
    color: var(--color-primary);
  }
}

.word.correct-word {
    background-color: rgba(209, 250, 223, 1);
    color: rgba(28, 169, 114, 1);
}
.word.incorrect-word {
    background-color: rgba(254, 228, 226, 1);
    color: rgba(217, 45, 32, 1);
}
.word.not-hightlight {
    background-color: rgba(254, 240, 199, 1);
    color: rgba(220, 104, 3, 1);
}
.iqt-word:hover {
    color: rgba(28, 169, 114, 1);
}
</style>
