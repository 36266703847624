<template>
  <v-sheet>
    <v-sheet class="pt-3 px-2" variant="flat">
      <v-tabs  hide-slider>
        <v-tab
          v-for="(menu, i) in topics"
          :key="i"
          :class="[
            (activeQTab === menu.id || hoverTabId === menu.id) ? ' kiwi-bg-primary qbt-active-tab-menu kiwi-text-white' : '',
          ]"
          class="mr-2 kiwi-border-light-grey-4 text-none kiwi-text kiwi-text-light-black-2 qbt-tab-menu"
          style="border: 1px solid transparent; border-radius: 8px 8px 0 0"
          @click="setActiveTab(menu.id, menu.children.length ? menu.children[0].id : null)"
          @mouseenter="showSubMenuMethod(menu.id)"
          ><img :src="activeQTab === menu.id ? tabs[i].icon_w : tabs[i].icon" class="mr-2 size-18" /> {{ menu.title }}</v-tab
        >
      </v-tabs>
    </v-sheet>
    <v-divider class="kiwi-border-primary"></v-divider>
    <v-sheet variant="flat" class="position-relative">
      <tempalate v-if="showSubMenu || showSubMenuOnHover">
        <div :class="mode === 'exam' ? 'position-absolute' : ''" style="top: 0; left: 0; z-index: 1; width: 100%;" @mouseleave="hideSubMenu" @click="hideSubMenu">
          <v-sheet
            class="kiwi-bg-light-grey-7 py-2 px-4 d-flex flex-wrap"
            variant="flat"
            style="margin: 0 -8px"
          >
            <v-chip
              label
              class="ma-1 kiwi-fs-14 forn-weight-semi-bold kiwi-qbt-topic border kiwi-radius-4 kiwi-text-light-black-2"
              variant="flat"
              v-for="(list, i) in chapterItems"
              :key="i"

              :class="
                (list.id === activeTopic)
                  ? 'kiwi-bg-primary-dim-50 kiwi-text-primary-overwrite kiwi-border-primary font-weight-semi-bold'
                  : 'kiwi-bg-white kiwi-bg-black'
              "
              @click="clickOnSubmenu(list)"
              size="large"
              style="font-size: 14px;"
            >
              {{ list.title }}
            </v-chip>
          </v-sheet>
          <v-divider></v-divider>

        </div>
      </tempalate>
      <div class="text-center pb-10 pt-10 mt-10 mb-10" v-if="fetching.tests">
        <Loading  />
      </div>
      <div class="py-4 px-4 py-md-5 px-md-5 kiwi-bg-light-grey-7 position-relative" @click="hideSubMenu" v-else>
        <v-sheet class="kiwi-mock-test-content kiwi-radius-8" variant="flat">
          <section class="individual-header py-3 px-4 font-weight-bold kiwi-fs-18 d-flex align-center">
            <v-icon class="mdi mdi-arrow-left cursor-pointer mr-2" @click="$router.go(-1)" /> {{ formatString(activeSlug) }}
          </section>
          <v-divider />
          <section class="content py-3 px-4" v-if="mode !== 'exam'">
            <div class="py-3 px-4 kiwi-radius-4" style="background-color: #F5F6F7;">
              <Test
                v-for="(test, i) in questionsBasedTests"
                :key="i"
                class="my-4"
                :test="test"
                :index="(i + (pagination.page > 1 ? ((pagination.page * 10) - pagination.pageSize) : 0))"
                :offset="pagination.page"
                :type="'individual-question'"
              />
            </div>
          </section>
          <section class="content py-3 px-4" v-else>
            <div class="py-3 px-4 kiwi-radius-4" style="background-color: #F5F6F7;">
            <ExamInstance v-if="questionsBasedTests.length":question="questionsBasedTests[0]" />
            </div>
          </section>
        </v-sheet>

      </div>
    </v-sheet>
    <v-divider></v-divider>

    <div>
      <v-sheet class="kiwi-bg-light-grey-7 kiwi-text-black py-3 px-4">
        <v-row>
          <v-col cols="12" lg="4" style="min-height: 50px;">
            <div class="d-flex align-center" v-if="$route.name === 'individual-question-test'">
              <v-select
                border
                class="kiwi-bg-white kiwi-border-light-grey-6 mr-2 limit-select"
                :items="[10, 15, 20, 25]"
                v-model="pagination.pageSize"
                flat
                variant="outlined"
                placeholder="1"
                hide-details
                style="max-width: 90px;"
                @update:modelValue="() => { pagination.page = 1; getIndividualQuestionTest(activeTopic) }"
              ></v-select>
            <span v-if="questionsBasedTests.length">Showing: {{ showing }} to {{ showing + questionsBasedTests.length - (showing === 1 ?  1 : 0)  }} </span>
            </div>
            <div class="d-flex align-center" v-else>
            </div>
          </v-col>
          <v-col cols="12" lg="8" class="text-right">
            <v-pagination
              v-if="mode !== 'exam'"
              v-model="pagination.page"
              :length="pageCount"
              :total-visible="5"
              variant="text"
              @update:modelValue="getIndividualQuestionTest(activeTopic)"
              density="compact"
            ></v-pagination>
            <QuestionInstancePagination v-else />
          </v-col>
        </v-row>
      </v-sheet>
    </div>
    <div class="pb-4 px-4 pb-md-5 px-md-5 kiwi-bg-light-grey-7"  v-if="mode === 'exam'">
      <EvaluationInstance answer="" :slug="questionsBasedTests[0]?.topic_slug" />
    </div>
  </v-sheet>
</template>

<script setup>
import Test from "@/components/Dashboard/QuestionCard.vue";
import ExamInstance from '../Exam/IndividualQuestion/IndividualQuestionInstance.vue'
import EvaluationInstance from '../Evaluation/IndividualQuestionEvaluation.vue'
import { ref, onMounted, computed, watch , nextTick} from "vue";
import { useIndividualQustionsTestStore } from "@/stores/individual-question-test-store";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import QuestionInstancePagination from "@/components/IndividualQuestion/QuestionInstancePagination.vue";
const props = defineProps(['mode'])
const route = useRoute()
const router = useRouter()

const questionBasedStore = useIndividualQustionsTestStore();
const { getIndividualQuestionTest, getIndividualQuestion,  getAllTopics , getPreviousAnswers} = questionBasedStore;
const { questionsBasedTests, pagination, total, topics, fetching ,currentQuestionOffset , isSubmitted , questionOffset } =
storeToRefs(questionBasedStore);

const showSubMenu = ref(false)
const paginationLimiter = ref(10);
if(route.name !== 'individualQuestion') {
  pagination.value.page = 1
}
else if(route.name === 'individualQuestion') {
  pagination.value.page = parseInt(route.params.index)
  paginationLimiter.value = 1;
}
const pageCount = computed(() => {
  return Math.ceil(total.value / pagination.value.pageSize);
});
getAllTopics()
const iconMap = {
    speaking: {
        icon: '/assets/voice_black.svg',
        icon_w: '/assets/voice_white.svg',
    },
    writing: {
        icon: '/assets/pencil-edit-01_black.svg',
        icon_w: '/assets/pencil-edit-01_white.svg',
    },
    reading: {
        icon: '/assets/book-open-black.svg',
        icon_w: '/assets/book-open-white.svg',
    },
    listening: {
        icon: '/assets/headphones-black.svg',
        icon_w: '/assets/headphones-white.svg',
    },
}
const tabs = computed(() => {
    return topics.value.map(topic => {
        return {
            ...(iconMap[topic.slug] || {}),
            label: topic.title,
            value: topic.slug,
            id: topic.id,
        }
    })
})


const activeQTab = ref(route.query.m ? parseInt(route.query.m) : JSON.parse(localStorage.getItem('activeQTab')));
const activeTopic = ref(route.query.topic ? parseInt(route.query.topic) : null);
const hoverTabId = ref(null);
const showSubMenuOnHover = ref(false);
if(route.name === 'individualQuestion') {
  activeTopic.value = parseInt(route.params.topic_id)
}
const setActiveTab = (m, topic = null) => {
   router.replace({
      name: 'individual-question-test',
      params: { ...route.params},
      query: { ...route.query , m:m }
    });
  isSubmitted.value =false
  localStorage.setItem('activeQTab', JSON.stringify(m));
  activeTopic.value = topic
  activeQTab.value = m
  hoverTabId.value = m
  currentQuestionOffset.value = 1;
  showSubMenuMethod(m)
}
const showSubMenuMethod = (id) => {
  if(props.mode === 'exam') {
    showSubMenuOnHover.value = true;
    activeQTab.value = id;
  }
  if(activeQTab.value === id) {
    showSubMenu.value = true;
  }
}
const chapterItems = computed(() => {
  let topic =  {}
  topic = topics.value.find(t => t.id === activeQTab.value)
    if(!topic) {
        return [];
    }
    return topic.children
})

const activeSlug = computed(() => {
  // const index = chapterItems.value?.
  // filter(ch => ch.id === (route.name === 'individualQuestion' ? parseInt(route.params.topic_id) : parseInt(activeTopic.value)))[0]?.title
  const index = chapterItems.value?.filter(ch => ch.id === parseInt(activeTopic.value))[0]?.title
  return index
})
watch(topics, (newVal, oldVal) => {
    if(!activeQTab.value && newVal.length) {
      activeQTab.value = topics.value[0].id
    }
}, {immediate: true, deep: true})
watch(activeQTab, (newVal, oldVal) => {
  if(activeQTab.value && !activeTopic.value && chapterItems.value.length) {
       activeTopic.value = chapterItems.value[0].id
    }
}, {immediate: true, deep: true})
watch(activeTopic,  (newVal, oldVal) => {
    if(activeTopic.value) {
      pagination.value.page = 1
      if(route.name === 'individual-question-test') {
        getIndividualQuestionTest(activeTopic.value)
      } else if(route.name === 'individualQuestion') { // pagination.value.page = parseInt(route.params.index)
        router.replace({name: 'individualQuestion', params: {...route.params, topic_id: newVal}})
        setTimeout(() => {
          getIndividualQuestion(route.params.topic_id, 1, currentQuestionOffset , true).then(res =>{
              getPreviousAnswers(route.params.topic_id, question.value.id);
        });
        }, 300)
      }
    }
}, {immediate: true})
watch(chapterItems , (newVal , oldVal) =>{
  if (newVal) {
    const matchingTopic = topics.value.find(topic => {
      if(route.name === 'individual-question-test') {
        return topic.children.find(child => child.id === activeTopic.value);
      } else if(route.name === 'individualQuestion') {
        return topic.children.find(child => child.id === parseInt(route.params.topic_id));
      }
    });
    if(matchingTopic && !activeQTab.value) {
      activeQTab.value= matchingTopic.id;
    }
  }
})
watch(props.mode, (newVal, oldVal) => {
  if(newVal && props.mode === 'exam') {
    showSubMenu.value = false;
  } else if(props.mode !== 'exam' || props.mode === undefined) {
    showSubMenu.value = true;
  }
})

if(props.mode === 'exam') {
  showSubMenu.value = false;
} else if(props.mode !== 'exam' || props.mode === undefined) {
  showSubMenu.value = true;
}
const hideSubMenu = () => {
  if(props.mode === 'exam') {
    showSubMenu.value = false;
    showSubMenuOnHover.value = false;
    activeQTab.value = hoverTabId.value
    activeTopic
  }
}
const showing = computed(() => {
  return pagination.value.page <= 1 ? 1 : (pagination.value.pageSize * (pagination.value.page - 1))
})

const clickOnSubmenu = (list) => {
  currentQuestionOffset.value = 1;
  questionOffset.value = 1;
  isSubmitted.value  = false
  const m = topics.value.find(t => t.children.find(c => c.id === list.id) ? true : false)
  if(m) {
    setActiveTab(m.id, m.children.length ? m.children[0].id : null)
    activeQTab.value = m.id;
    hoverTabId.value = m.id;
  }
  activeTopic.value = list.id;
  // router.replace({name: 'individualQuestion', params: {...route.params, topic_id: activeTopic.value}})
}

const question = computed(()=>{
  return questionsBasedTests.value[0]
})

const  formatString = (slug) =>{
  if (props.mode === 'exam' && slug) {
    const closingParenthesisIndex = slug.indexOf(")");
    const openingParenthesisIndex = slug.lastIndexOf("(");
    if (closingParenthesisIndex !== -1 && openingParenthesisIndex !== -1) {
      const contentInsideParentheses = slug.slice(openingParenthesisIndex + 1, closingParenthesisIndex).trim();
      return `${contentInsideParentheses}_${questionOffset.value}`;
    }
    if(closingParenthesisIndex === -1 && openingParenthesisIndex === -1){
      //had to this manual as mcq is missig slug
      return `MCQ_${questionOffset.value}`;
    }
  }
  return slug;
}

onMounted(() => {
  hoverTabId.value = activeQTab.value;
})
</script>

<style>

</style>
